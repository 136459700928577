<script lang="ts" setup>
import type { PageCollection, Games } from "@/types";

const { open } = useNlcModals();
const { data: collections } = useGetPageCollectionsData();
const loginGuard = useLoginGuard();

const openGamesModal = (collection: PageCollection) => {
	open("LazyOModalCollectionsGames", { title: collection.title, collectionSlug: collection.slug });
};
const handleNavigateToPage = () => {
	loginGuard({
		success: () => navigateTo("/collections"),
		fail: () => open("LazyOModalSignup")
	});
};
</script>
<template>
	<div class="home-collection-wrapper" data-tid="collections">
		<MSliderWrapper
			:list="collections ?? []"
			title="Collections"
			showAll
			icon="24/collections"
			@view-all-action="handleNavigateToPage"
		>
			<template #default="{ item }">
				<MCollectionCard
					class="keen-slider__slide"
					:title="item.title"
					:logo="item.logo"
					:games="item.games as Games"
					:data-tid="`collection-${item.slug}`"
					:slug="item.slug"
					@click="openGamesModal(item)"
				/>
			</template>
		</MSliderWrapper>
	</div>
</template>
